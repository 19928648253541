import React from 'react'
import { graphql } from 'gatsby'

import * as styles from '../styles/pages/all-affiliates.module.scss'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

export const Head = () => <title>All Affiliates | GROWoneAFRICA&trade;</title>

const AllAffiliatesPage = ({ data }) => {
  const allAffiliates = data.allSanityAffiliate.nodes

  const affiliateElements = allAffiliates.map((affiliate) => {
    const {
      name,
      logo,
      email,
      telephoneNumber,
      address
    } = affiliate

    return (
      <article className={styles.affiliateCard}>
        <div className={styles.imageContainer}>
          <GatsbyImage image={getImage(logo.asset)} alt={name} />
        </div>
        <h2>{name}</h2>
        <div>
          <div>
            <h3>Email</h3>
            <span>
              <a href={`mailto:${email}`}>{email}</a>
            </span>
          </div>
          <div>
            <h3>Telephone Number</h3>
            <span>
              <a href={`tel:${telephoneNumber}`}>{telephoneNumber}</a>
            </span>
          </div>
          <div>
            <h3>Address</h3>
            <span>{address}</span>
          </div>
        </div>
      </article>
    )
  })

  return (
    <main className={`page-container ${styles.allAffiliatesPage}`}>
      <h1>Affiliates</h1>
      <section className={styles.affiliatesContainer}>
        {affiliateElements}
      </section>
    </main>
  )
}

export const query = graphql`
  query AllAffiliatesPageQuery {
    allSanityAffiliate(sort: {fields: name}) {
      nodes {
        name
        logo {
          asset {
            gatsbyImageData( height: 200 )
          }
        }
        email
        telephoneNumber
        address
      }
    }
  }
`

export default AllAffiliatesPage